<script setup lang="ts">
import { TransitionRoot } from '@headlessui/vue'

const props = defineProps<{
  loading: boolean
}>()

const { loading } = toRefs(props)
</script>

<template>
  <div class="relative w-full">
    <div v-if="loading" class="flex justify-center items-center h-full bg-white">
      <div class="animate-ping  absolute rounded-md">
        <div class="relative flex h-4 w-4">
          <span class="absolute inline-flex h-full w-full rounded-full bg-indigo-600 opacity-75" />
          <span class="relative inline-flex rounded-full h-4 w-4 bg-sky-500" />
        </div>
      </div>
    </div>

    <TransitionRoot
      v-else
      show
      enter-active="transition duration-100 ease-out"
      enter-from="transform scale-95 opacity-0"
      enter-to="transform scale-100 opacity-100"
      leave-active="transition duration-75 ease-in"
      leave-from="transform scale-100 opacity-100"
      leave-to="transform scale-95 opacity-0"
    >
      <slot />
    </TransitionRoot>
  </div>
</template>
